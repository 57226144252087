const options = [
  {
    key: '',
    label: 'sidebar.dashboard',
    leftIcon: 'dashboard',
    roles: ['FBB Operations', 'FBB Operations Lead', 'Admin', 'Marketing Ops', 'Tester']
  },
  {
    key: 'applications',
    label: 'sidebar.applications',
    leftIcon: 'container',
    roles: [
      'FBB Operations',
      'FBB Operations Lead',
      'Customer Service',
      'DIGI Store Staff',
      'DIGI Dealer',
      'GENEX',
      'Admin',
      'DIGI Store Manager',
      'Marketing Ops',
      'Telesales Staff',
      'Telesales Manager',
      'Performance Analyst',
      'Tester',
      'Alternate Channel'
    ],
    children: [
      {
        key: 'applications-digi',
        label: 'sidebar.digi',
        leftIcon: 'container',
        roles: ['Alternate Channel']
      },
      {
        key: 'applications-celcom',
        label: 'sidebar.celcom',
        leftIcon: 'container',
        roles: ['Alternate Channel']
      }
    ]
  },
  {
    key: 'leads',
    label: 'sidebar.leads',
    leftIcon: 'container',
    roles: ['FBB Operations', 'FBB Operations Lead', 'Admin', 'Tester']
  },
  {
    key: 'lead-assignment',
    label: 'sidebar.lead-assignment',
    leftIcon: 'team',
    roles: ['FBB Operations Lead'],
    children: [
      {
        key: 'assign-tasks',
        label: 'sidebar.assign-tasks',
        leftIcon: 'container',
        roles: ['FBB Operations Lead']
      },
      {
        key: 'unassigned-tasks',
        label: 'sidebar.unassigned-tasks',
        leftIcon: 'container',
        roles: ['FBB Operations Lead']
      }
    ]
  },
  {
    key: 'corporateApplications',
    label: 'sidebar.corporateApplications',
    leftIcon: 'container',
    roles: ['Admin', 'Corporate Admin', 'Corporate Dealer', 'Marketing Ops', 'FBB Operations', 'FBB Operations Lead', 'Tester']
  },
  {
    key: 'radius-profiles',
    label: 'sidebar.radiusProfiles',
    leftIcon: 'user',
    roles: ['FBB Operations', 'FBB Operations Lead', 'Corporate Admin', 'Admin', 'Tester']
  },
  {
    key: 'groups',
    label: 'sidebar.radiusGroups',
    leftIcon: 'team',
    roles: ['FBB Operations', 'FBB Operations Lead', 'Admin', 'Tester']
  },
  {
    key: 'internet-protocol',
    label: 'sidebar.internetProtocol',
    leftIcon: 'apartment',
    roles: ['Corporate Admin', 'Admin']
  },
  {
    key: 'address-upload',
    label: 'sidebar.addressUpload',
    leftIcon: 'home',
    roles: ['Wholesales', 'Admin', 'FBB Operations', 'FBB Operations Lead', 'Tester']
  },
  {
    key: 'marketingOps',
    label: 'sidebar.marketingOps',
    leftIcon: 'desktop',
    roles: ['Admin', 'Marketing Ops'],
    children: [
      {
        key: 'serial-upload',
        label: 'sidebar.serialUpload',
        leftIcon: 'container',
        roles: ['Admin', 'Marketing Ops']
      },
      {
        key: 'saf-upload',
        label: 'sidebar.safUpload',
        leftIcon: 'container',
        roles: ['Admin', 'Marketing Ops']
      }
    ]
  },
  {
    key: 'users',
    label: 'sidebar.users',
    leftIcon: 'idcard',
    roles: ['Admin', 'User Admin', 'Partner Support Admin']
  },
  {
    key: 'settings',
    label: 'sidebar.operations',
    leftIcon: 'desktop',
    roles: ['FBB Operations', 'FBB Operations Lead', 'Admin', 'Marketing Ops'],
    children: [
      {
        key: 'plans',
        label: 'sidebar.plans',
        leftIcon: 'apartment',
        roles: ['FBB Operations', 'FBB Operations Lead', 'Admin']
      },
      {
        key: 'simserialnumbers',
        label: 'sidebar.serialNumbers',
        leftIcon: 'barcode',
        roles: ['FBB Operations', 'FBB Operations Lead', 'Admin']
      },
      {
        key: 'promotions-config',
        label: 'sidebar.promotions',
        leftIcon: 'gift',
        roles: ['Admin']
      },
      // {
      //   key: 'tdc-csv',
      //   label: 'sidebar.tdccsv',
      //   leftIcon: 'container',
      //   roles: ['FBB Operations', 'FBB Operations Lead', 'Admin']
      // },
      {
        key: 'assignment-config',
        label: 'sidebar.assignmentConfig',
        leftIcon: 'container',
        roles: ['FBB Operations Lead']
      },
      {
        key: 'task-assign-upload',
        label: 'sidebar.uploadTask',
        leftIcon: 'container',
        roles: ['FBB Operations Lead']
      },
      {
        key: 'wfm-file-upload',
        label: 'sidebar.wfmUpload',
        leftIcon: 'container',
        roles: ['FBB Operations Lead']
      },
      {
        key: 'appointment-config',
        label: 'sidebar.appointmentConfig',
        leftIcon: 'container',
        roles: ['FBB Operations', 'FBB Operations Lead', 'Admin', 'Marketing Ops']
      },
      {
        key: 'installers',
        label: 'sidebar.installers',
        leftIcon: 'user',
        roles: ['FBB Operations', 'FBB Operations Lead', 'Admin', 'Marketing Ops']
      },
      {
        key: 'installers/regions',
        label: 'sidebar.installer_region',
        leftIcon: 'global',
        roles: ['FBB Operations', 'FBB Operations Lead', 'Admin', 'Marketing Ops']
      }
    ]
  }
];
export default options;
